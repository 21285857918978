<template>
  <b-sidebar
    id="add-new-regra-sidebar"
    :visible="isAddNewRegraSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-regra-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar nova regra
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Canal -->
          <validation-provider
            #default="validationContext"
            name="Canal"
            rules="required"
          >
            <b-form-group
              label="Selecione o canal"
              label-for="canal"
            >
              <v-select
                v-model="regraData.canal_id"
                :options="channelsOptions"
                :clearable="false"
                label="nome"
                placeholder="Pesquisar..."
                :reduce="channel => channel.id"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Periodo Sinal -->
          <validation-provider
            #default="validationContext"
            name="Periodo"
            rules="required"
          >
            <b-form-group
              label="Quando?"
              label-for="periodo"
            >
              <v-select
                v-model="regraData.filtro_periodo_sinal"
                :options="periodoOptions"
                :clearable="false"
                label="title"
                placeholder="Pesquisar..."
                :reduce="periodo => periodo.sinal"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Periodo Dias -->
          <validation-provider
            #default="validationContext"
            name="Periodo Dias"
            rules="required|integer"
          >
            <b-form-group
              label="Quantidade de dias"
              label-for="periodo_dias"
            >
              <b-form-input
                id="periodo_dias"
                v-model="regraData.filtro_periodo_dias"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Horario Cobrança -->
          <validation-provider
            #default="validationContext"
            name="Horário"
            rules="required"
          >
            <b-form-group
              label="Horário da cobrança"
              label-for="horario"
            >
              <b-form-timepicker
                id="horario"
                placeholder="Selecione o horário"
                v-model="regraData.horario_cobranca"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Situacao Titulo -->
          <validation-provider
            #default="validationContext"
            name="Situacao Titulo"
            rules="required"
          >
            <b-form-group
              label="Situação do Titulo"
              label-for="situacao_titulo"
            >
              <v-select
                v-model="regraData.filtro_situacao_titulo"
                :options="situacaoTituloOptions"
                :clearable="false"
                label="name"
                placeholder="Pesquisar..."
                :reduce="situacaoTitulo => situacaoTitulo.name"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Credor -->
          <validation-provider
            #default="validationContext"
            name="Credor"
            rules=""
          >
            <b-form-group
              label="Selecione o credor"
              label-for="credor"
            >
              <v-select
                v-model="regraData.credorId"
                :options="credoresOptions"
                :clearable="true"
                label="nome"
                placeholder="Pesquisar..."
                :reduce="credor => credor.id"
                @search="onSearch"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Mensagem -->
          <validation-provider
            #default="validationContext"
            name="Mensagem"
            rules="required"
          >
            <b-form-group
              label="Selecione a mensagem"
              label-for="mensagem"
            >
              <v-select
                v-model="regraData.mensagem_id"
                :options="mensagensOptions"
                :clearable="false"
                label="titulo"
                placeholder="Pesquisar..."
                :reduce="mensagem => mensagem.id"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTimepicker 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onBeforeMount } from '@vue/composition-api'
import { required , integer} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTimepicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRegraSidebarActive',
    event: 'update:is-add-new-regra-sidebar-active',
  },
  props: {
    isAddNewRegraSidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      required,
      integer
    }
  },
  setup(props, { emit }) {
    const blankRegraData = {
      filtro_periodo_sinal: null, //ok
      filtro_periodo_dias: '', //ok
      horario_cobranca: '', //ok
      filtro_valor_sinal: null,
      filtro_valor: '',  
      filtro_situacao_titulo: 'TODOS', //ok
      credor_id: null, //ok
      mensagem_id: null, //ok
      canal_id: null, //ok
      regua_id: 1 //ok
    }

    const channelsOptions = ref([])
    const mensagensOptions = ref([])
    const credoresOptions = ref([])

    const periodoOptions = ref([
      { title: 'Antes do vencimento', sinal:'<' }, 
      { title: 'No dia do vencimento', sinal:'=' }, 
      { title: 'Após o vencimento', sinal: '>' }
    ])

    const situacaoTituloOptions = ref([
      { name: 'NEGATIVADO'}, 
      { name: 'PRESCRITO'}, 
      { name: 'TODOS'}
    ])

    const debounce = (fn, delay) => {
      let timeout

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
          fn(...args)
        }, delay)
      }
    }
    
    const onSearch = debounce((text,loading) => {
      loading(true)
      axios
        .get('regua/filtroCredores', {
          params: {
            busca: text
          }
        })
        .then(response => {
          const { data } = response.data  
          credoresOptions.value = data
        })
        .catch(error => reject(error))  
        .finally(() => loading(false))    
    }, 500)

    onBeforeMount(() => {
      axios
      .get('regua/mensagens')
      .then(response => {
        const { data } = response.data  
        mensagensOptions.value = data
      })
      .catch(error => reject(error))  

      axios
      .get('regua/canais')
      .then(response => {
        const { data } = response.data  
        channelsOptions.value = data
      })
      .catch(error => reject(error))  
    })

    const regraData = ref(JSON.parse(JSON.stringify(blankRegraData)))
    const resetRegraData = () => {
      regraData.value = JSON.parse(JSON.stringify(blankRegraData))
    }

    const onSubmit = () => {
      axios
      .post('regra-cobranca', {
        ...regraData.value
      })
      .then(response => {
        emit('refetch-data')
        emit('update:is-add-new-regra-sidebar-active', false)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Cadastrar regra',
            icon: 'XIcon',
            variant: 'danger',
            text: `Falha ao cadastrar regra`,
          },
        })
      })  
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegraData)

    return {
      regraData,
      onSubmit,

      channelsOptions,
      periodoOptions,
      situacaoTituloOptions,
      credoresOptions,
      debounce,
      onSearch,
      mensagensOptions,
      
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
