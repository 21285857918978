<template>
  <div>
     <regra-cobranca-add-new
      :is-add-new-regra-sidebar-active.sync="isAddNewRegraSidebarActive"
      @refetch-data="refetchData"
    />
    <b-card>
      <app-timeline>
        <!-- REGRA -->
        <app-timeline-item v-for="regra in regras" :key="regra.id" icon="SendIcon">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6 class="text-primary" v-if="regra.filtro_periodo_sinal == '='">NO DIA DO VENCIMENTO : {{ regra.horario_cobranca }} </h6>
            <h6 class="text-primary" v-else>{{ regra.filtro_periodo_dias }} dias - {{ regra.filtro_periodo_sinal == '>' ? 'após o' : 'antes do' }} vencimento : {{ regra.horario_cobranca }} </h6>
            <small class="text-muted">#{{ regra.id }}</small>
          </div>
          <p>Enviar <b class="text-success">{{ regra.canal.nome }}</b> com mensagem <b>{{ regra.mensagem.titulo }}</b></p>
          <p>Títulos: <b class="text-success">{{ regra.filtro_situacao_titulo }}</b> | Credor: <b>{{ regra.credor_id != null ? regra.credor.nome : 'TODOS' }}</b></p>
          <b-button
            v-b-toggle.report-list-with-icon
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-danger"
            @click="showModalDelete(regra.id)"
          >
            Remover regra
            <feather-icon
              icon="TrashIcon"
              class="ml-50"
            />
          </b-button>
        </app-timeline-item>
        <!-- ADICIONAR NOVA REGRA -->
        <app-timeline-item icon="PlusIcon">
          <b-button
            v-b-toggle.report-list-with-icon
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="primary"
            @click="isAddNewRegraSidebarActive = true"
          >
            Adicionar regra
            <feather-icon
              icon="PlusIcon"
              class="ml-50"
            />
          </b-button>
        </app-timeline-item>

      </app-timeline>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,VBToggle, VBTooltip,BCard
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import RegraCobrancaAddNew from './RegraCobrancaAddNew.vue'
import { ref, onUnmounted, onBeforeMount } from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BButton,
    BCard,

    RegraCobrancaAddNew
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  setup({ emit }) {
    
    const isAddNewRegraSidebarActive = ref(false)

    const regras = ref([])

    onBeforeMount(() => {
      axios
      .get('regra-cobranca')
      .then(response => {
        // const { data } = response.data  
        regras.value = response.data
      })
      .catch(error => reject(error))  

    })

    function showModalDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Você deseja prosseguir com a exclusão?', {
          title: 'Confirme sua ação',
          size: 'sm',
          modalClass: 'modal-danger',
          okVariant: 'danger',
          okTitle: 'Sim, confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value == true){
            axios.delete('regra-cobranca/'+id)
            .then((response) => {
              this.refetchData()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de regra',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `Regra excluída com sucesso!`,
                },
              })
             
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `Não foi possível excluir sua regra ):`,
                },
              })
            });
          }
        })
    }

    const refetchData = () => {
      axios
      .get('regra-cobranca')
      .then(response => {
        // const { data } = response.data  
        regras.value = response.data
      })
      .catch(error => reject(error))  
    }

    return {
      isAddNewRegraSidebarActive,
      regras,
      showModalDelete,
      refetchData
    }
  },
}
</script>
 